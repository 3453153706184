// Utilitaires pour les diagrammes Mermaid
let mermaidInitialized = false;

export async function initMermaid(): Promise<void> {
  if (mermaidInitialized) return;
  
  try {
    // Charger Mermaid dynamiquement
    const mermaid = await import('mermaid');
    
    // Configuration Mermaid
    mermaid.default.initialize({
      startOnLoad: true,
      theme: 'neutral',
      securityLevel: 'loose',
      fontFamily: '"SF Pro Display", -apple-system, BlinkMacSystemFont, sans-serif',
      fontSize: 14,
      flowchart: {
        curve: 'basis'
      }
    });
    
    // Initialiser tous les diagrammes
    setTimeout(() => {
      mermaid.default.run();
    }, 100);
    
    mermaidInitialized = true;
    console.log('Mermaid initialisé avec succès');
  } catch (error) {
    console.error('Erreur lors du chargement de Mermaid:', error);
  }
} 