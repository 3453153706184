import React from 'react';
import { motion } from 'framer-motion';
import { blogPosts } from '../../data/blog-posts';
import { useNavigate } from 'react-router-dom';

const Blog = () => {
  const navigate = useNavigate();

  const handleArticleClick = (postId: string) => {
    navigate(`/blog/${postId}`);
  };

  // Définir les couleurs pour les différentes catégories d'articles
  const getCategoryColor = (category: string) => {
    switch (category.toLowerCase()) {
      case 'ai':
        return {
          gradient: 'linear-gradient(135deg, #0066cc, #5ac8fa)',
          icon: '/images/edge-ai-icon.png'
        };
      case 'innovation':
        return {
          gradient: 'linear-gradient(135deg, #5e5ce6, #bf5af2)',
          icon: '/images/genius-ai-icon.png'
        };
      case 'technology':
        return {
          gradient: 'linear-gradient(135deg, #ff9500, #ff2d55)',
          icon: '/images/music-ai-icon.png'
        };
      default:
        return {
          gradient: 'linear-gradient(135deg, #34c759, #30b0c7)',
          icon: '/images/edge-ai-icon.png'
        };
    }
  };

  return (
    <section id="blog" className="py-20 relative overflow-hidden">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <span className="text-xl font-medium inline-block mb-3"
            style={{
              background: 'linear-gradient(90deg, #0066cc, #5ac8fa)',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}>
            Notre Blog
          </span>
          <h2 className="text-4xl md:text-5xl font-semibold mb-6 text-[#333333]">
            Dernières Actualités
          </h2>
          <p className="max-w-3xl mx-auto text-lg text-[#666666]">
            Découvrez nos dernières réalisations, innovations et annonces
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post, index) => {
            const { gradient, icon } = getCategoryColor(post.category);
            return (
              <motion.article
                key={post.id}
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                whileHover={{ y: -15, transition: { duration: 0.3 } }}
                onClick={() => handleArticleClick(post.id)}
                className="cursor-pointer"
                style={{
                  animation: `float 6s ease-in-out infinite ${index * 0.5}s`,
                  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
                  borderRadius: '24px',
                  overflow: 'hidden',
                  backgroundColor: 'white',
                  transform: 'translateZ(0)',
                  position: 'relative'
                }}
              >
                {/* En-tête de la carte avec dégradé et icône */}
                <div 
                  style={{
                    background: gradient,
                    padding: '20px',
                    borderTopLeftRadius: '24px',
                    borderTopRightRadius: '24px'
                  }}
                >
                  <img 
                    src={icon} 
                    alt={post.category} 
                    className="w-20 h-20 mx-auto my-3 drop-shadow-lg"
                    style={{ display: 'block' }}
                  />
                </div>
                
                {/* Contenu de la carte */}
                <div style={{ padding: '24px' }} className="paper-bg">
                  <div className="mb-4">
                    <span className="text-sm font-medium" style={{ color: '#0071e3' }}>{post.category}</span>
                    <h3 style={{ fontSize: '22px', fontWeight: 600, marginBottom: '10px', color: '#333333' }}>{post.title}</h3>
                    <time className="text-sm" style={{ color: '#666666' }}>{post.date}</time>
                  </div>
                  
                  <p style={{ fontSize: '16px', color: '#666666', lineHeight: 1.5 }} className="mb-4 line-clamp-3">{post.summary}</p>
                  
                  <div className="flex flex-wrap gap-2 mt-4">
                    {post.tags.map(tag => (
                      <span
                        key={tag}
                        className="px-3 py-1 rounded-full text-sm"
                        style={{ 
                          backgroundColor: 'rgba(0, 113, 227, 0.1)', 
                          color: '#0071e3' 
                        }}
                      >
                        {tag}
                      </span>
                    ))}
                  </div>

                  <div className="mt-5">
                    <motion.div
                      whileHover={{ x: 5 }}
                      style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        color: '#0071e3', 
                        fontWeight: 500, 
                        fontSize: '15px',
                        cursor: 'pointer'
                      }}
                    >
                      Lire l'article
                      <svg 
                        className="w-4 h-4 ml-1" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24" 
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M9 5l7 7-7 7" 
                        />
                      </svg>
                    </motion.div>
                  </div>
                </div>

                {/* Effet de lumière */}
                <div 
                  style={{
                    position: 'absolute',
                    width: '300px',
                    height: '300px',
                    borderRadius: '50%',
                    background: post.category.toLowerCase() === 'ai'
                      ? 'radial-gradient(circle, rgba(92, 170, 255, 0.15) 0%, rgba(255, 255, 255, 0) 70%)' 
                      : post.category.toLowerCase() === 'innovation'
                        ? 'radial-gradient(circle, rgba(191, 90, 242, 0.15) 0%, rgba(255, 255, 255, 0) 70%)'
                        : 'radial-gradient(circle, rgba(255, 149, 0, 0.15) 0%, rgba(255, 255, 255, 0) 70%)',
                    zIndex: -1,
                    opacity: 0.6,
                    top: '-40px',
                    right: '-40px',
                    filter: 'blur(40px)'
                  }}
                ></div>
              </motion.article>
            );
          })}
        </div>
      </div>

      <style>
        {`
          @keyframes float {
            0% { transform: translateY(0px); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0px); }
          }
          .paper-bg {
            background-color: #fdfbf7;
            background-image: linear-gradient(rgba(255, 255, 255, 0.2) 50%, rgba(0, 0, 0, 0.01) 50%);
            background-size: 100% 0.1rem;
          }
        `}
      </style>
    </section>
  );
};

export default Blog;
