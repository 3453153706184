import { useEffect, useRef } from 'react';

const HeroBackground = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    
    let width = window.innerWidth;
    let height = window.innerHeight * 2;
    
    canvas.width = width;
    canvas.height = height;
    
    // Configuration des particules
    const particleCount = width > 768 ? 100 : 60;
    const particles: Array<{
      x: number;
      y: number;
      radius: number;
      color: string;
      speed: number;
      direction: number;
      velocity: { x: number; y: number };
    }> = [];
    
    // Créer des particules
    for (let i = 0; i < particleCount; i++) {
      const radius = Math.random() * 2 + 0.5;
      
      let color;
      const rand = Math.random();
      if (rand < 0.3) {
        color = 'rgba(0, 113, 227, 0.25)'; // Bleu Apple
      } else if (rand < 0.6) {
        color = 'rgba(60, 100, 240, 0.2)'; // Indigo
      } else {
        color = 'rgba(90, 200, 250, 0.2)'; // Bleu clair
      }
      
      particles.push({
        x: Math.random() * width,
        y: Math.random() * height,
        radius,
        color,
        speed: Math.random() * 0.5 + 0.2,
        direction: Math.random() * 360,
        velocity: { 
          x: (Math.random() - 0.5) * 0.3, 
          y: (Math.random() - 0.5) * 0.3 
        }
      });
    }
    
    // Animation
    function animate() {
      if (!ctx || !canvas) return;
      
      ctx.clearRect(0, 0, width, height);
      
      // Dessiner les particules
      particles.forEach(p => {
        ctx.beginPath();
        ctx.arc(p.x, p.y, p.radius, 0, Math.PI * 2);
        ctx.fillStyle = p.color;
        ctx.fill();
        
        // Mise à jour de la position
        p.x += p.velocity.x;
        p.y += p.velocity.y;
        
        // Rebond sur les bords
        if (p.x < 0 || p.x > width) p.velocity.x *= -1;
        if (p.y < 0 || p.y > height) p.velocity.y *= -1;
      });
      
      requestAnimationFrame(animate);
    }
    
    animate();
    
    // Gestion du redimensionnement
    function handleResize() {
      width = window.innerWidth;
      height = window.innerHeight * 2;
      
      if (canvas) {
        canvas.width = width;
        canvas.height = height;
      }
    }
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <canvas 
      ref={canvasRef} 
      className="absolute top-0 left-0 w-full h-full pointer-events-none z-0"
      style={{ 
        background: 'linear-gradient(180deg, rgba(255,255,255,0.99) 0%, rgba(245,247,252,0.99) 40%, rgba(255,255,255,0.99) 100%)',
        position: 'fixed',
      }}
    />
  );
};

export default HeroBackground; 