import React, { useState } from 'react';
import { toast } from 'react-hot-toast';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });
      
      if (response.ok) {
        toast.success('Message envoyé avec succès !');
        setFormData({ name: '', email: '', subject: '', message: '' });
        setIsSubmitted(true);
      }
    } catch (error) {
      console.error('Erreur:', error);
      toast.error('Une erreur est survenue');
    }
  };

  const handleNewMessage = () => {
    setIsSubmitted(false);
  };

  if (isSubmitted) {
    return (
      <div className="text-center py-10">
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-6" role="alert">
          <strong className="font-bold">Succès!</strong>
          <span className="block sm:inline"> Votre message a été envoyé avec succès.</span>
        </div>
        <p className="text-lg mb-6">Merci pour votre message. Nous vous répondrons dans les plus brefs délais.</p>
        <button
          onClick={handleNewMessage}
          className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
        >
          Envoyer un nouveau message
        </button>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="max-w-2xl mx-auto p-6">
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-200">Nom</label>
          <input
            type="text"
            required
            value={formData.name}
            onChange={(e) => setFormData({...formData, name: e.target.value})}
            className="mt-1 block w-full rounded-md bg-gray-800 border-gray-700"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-200">Email</label>
          <input
            type="email"
            required
            value={formData.email}
            onChange={(e) => setFormData({...formData, email: e.target.value})}
            className="mt-1 block w-full rounded-md bg-gray-800 border-gray-700"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-200">Sujet</label>
          <input
            type="text"
            required
            value={formData.subject}
            onChange={(e) => setFormData({...formData, subject: e.target.value})}
            className="mt-1 block w-full rounded-md bg-gray-800 border-gray-700"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-200">Message</label>
          <textarea
            required
            value={formData.message}
            onChange={(e) => setFormData({...formData, message: e.target.value})}
            rows={6}
            className="mt-1 block w-full rounded-md bg-gray-800 border-gray-700"
          />
        </div>

        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
        >
          Envoyer
        </button>
      </div>
    </form>
  );
};

export default ContactForm; 