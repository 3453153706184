import React from 'react';
import Layout from '../components/core/Layout';
import ContactForm from '../components/sections/Contact/ContactForm';

export default function Contact() {
  return (
    <Layout>
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white py-20">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl md:text-5xl font-bold text-center mb-8">Contactez-nous</h1>
          <p className="text-xl text-center text-gray-300 mb-12 max-w-3xl mx-auto">
            Nous sommes à votre écoute. Partagez vos questions, idées ou projets, et nous vous répondrons dans les plus brefs délais.
          </p>
          
          <div className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-xl shadow-2xl p-8 max-w-4xl mx-auto">
            <ContactForm />
          </div>
          
          <div className="mt-16 grid md:grid-cols-2 gap-8 text-center max-w-4xl mx-auto">
            <div className="p-6 rounded-lg bg-gray-800 bg-opacity-30">
              <div className="text-blue-400 text-3xl mb-4">📧</div>
              <h3 className="text-xl font-semibold mb-2">Email</h3>
              <p className="text-gray-300">contact@infinity-cloud.ch</p>
            </div>
            
            <div className="p-6 rounded-lg bg-gray-800 bg-opacity-30">
              <div className="text-blue-400 text-3xl mb-4">⏱️</div>
              <h3 className="text-xl font-semibold mb-2">Disponibilité</h3>
              <p className="text-gray-300">Lun-Ven, 9h-18h</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
} 