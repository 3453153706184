import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './styles/color-override.css';

// Create root element with type assertion for strict mode
const rootElement = document.getElementById('root') as HTMLElement;

// Create root and render app
ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
