import React from 'react';
import { motion } from 'framer-motion';

// Palette Avengers : rouge #a50000, bleu #3268bd, vert #00991f, gris #999999, violet #460061
const avengersColors = {
  red: '#a50000',
  blue: '#3268bd',
  green: '#00991f',
  gray: '#999999',
  purple: '#460061'
};

const heroTypes = [
  {
    id: 'sentinel',
    name: 'Sentinelle',
    description: "Au cœur de Plan-les-Ouates, notre infrastructure nouvelle génération incarne l'excellence suisse en matière d'IA. Une approche locale, éthique et souveraine pour façonner l'avenir numérique.",
    icon: '/images/swiss-shield-icon.png',
    color: avengersColors.blue,
    borderColor: '#1e4a9e',
    cardColor: 'bg-gradient-to-br from-blue-700 to-blue-900',
    abilities: ['Infrastructure Locale', 'Haute Performance', 'Éthique by Design']
  },
  {
    id: 'guardian',
    name: 'Gardiens du Code',
    description: "Une équipe d'experts dédiée à maintenir l'équilibre parfait entre performance et éthique dans nos systèmes d'IA.",
    icon: '/images/swiss-helmet-icon.png',
    color: avengersColors.red,
    borderColor: '#7d0000',
    cardColor: 'bg-gradient-to-br from-red-700 to-red-900',
    abilities: ['Sécurité Avancée', 'Audit Continu', 'Développement Éthique']
  },
  {
    id: 'architect',
    name: 'Architectes du Futur',
    description: "Innovateurs visionnaires qui conçoivent et déploient des solutions d'IA respectueuses et durables.",
    icon: '/images/swiss-arc-reactor.png',
    color: avengersColors.purple,
    borderColor: '#33004a',
    cardColor: 'bg-gradient-to-br from-purple-700 to-purple-900',
    abilities: ['Vision Stratégique', 'Innovation Responsable', 'Solutions Durables']
  }
];

const techSpecs = [
  'Mac Studio Ultra 3 × 2', 
  'Cluster ML 670B Params', 
  '96KB Tensor Stripes', 
  'Metal Sync API',
  '72.8 Gb/s Throughput',
  'Neural RAID 0'
];

const SwissAvengers = () => {
  return (
    <section className="py-16 relative overflow-hidden">
      {/* Fond avec effet lumineux */}
      <div className="absolute inset-0 bg-slate-900"></div>
      <div className="absolute top-0 left-0 w-full h-full opacity-10"
        style={{
          backgroundImage: 'url("data:image/svg+xml,%3Csvg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M30 0 L60 30 L30 60 L0 30 Z" fill="%23FFFFFF" fill-opacity="0.1"/%3E%3C/svg%3E")',
          backgroundSize: '60px 60px'
        }}
      ></div>
      <div className="absolute top-40 left-20 w-96 h-96 rounded-full filter blur-[120px] opacity-30" 
        style={{ backgroundColor: avengersColors.blue }}></div>
      <div className="absolute bottom-40 right-20 w-96 h-96 rounded-full filter blur-[120px] opacity-30"
        style={{ backgroundColor: avengersColors.red }}></div>
      
      {/* Contenu principal */}
      <div className="container mx-auto px-4 relative z-10">
        {/* Titre principal avec dégradé de couleurs Avengers */}
        <div className="text-center mb-8">
          <h1 
            className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6 bg-clip-text text-transparent" 
            style={{ 
              backgroundImage: `linear-gradient(to right, ${avengersColors.red}, ${avengersColors.blue}, ${avengersColors.purple})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              color: avengersColors.red // Fallback
            }}
          >
            L'alliance suisse pour une IA éthique, locale et performante
          </h1>
          <h2 
            className="text-5xl sm:text-6xl md:text-7xl font-bold mb-3 bg-clip-text text-transparent"
            style={{ 
              backgroundImage: `linear-gradient(to right, ${avengersColors.blue}, ${avengersColors.red}, ${avengersColors.purple})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              color: avengersColors.blue, // Fallback
              paddingBottom: '0.15em',
              lineHeight: '1.2'
            }}
          >
            Swiss Avengers
          </h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            La puissance des Mac Ultra en cluster pour des LLM locaux surpuissants
          </p>
        </div>
        
        {/* Image principale avec spécifications techniques au-dessus */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mb-16 relative"
        >
          {/* Spécifications techniques placées au-dessus de l'image */}
          <div className="flex justify-center flex-wrap gap-4 mb-4">
            {techSpecs.map((spec, index) => (
              <div 
                key={`spec-${index}`}
                className="px-4 py-1 rounded-md bg-black/70 backdrop-blur-sm text-white text-sm font-mono border"
                style={{ borderColor: Object.values(avengersColors)[index % Object.values(avengersColors).length] }}
              >
                {spec}
              </div>
            ))}
          </div>
          
          <div className="rounded-2xl overflow-hidden shadow-2xl mx-auto max-w-4xl">
            <img 
              src="/images/header-background.png" 
              alt="Swiss Avengers Technology" 
              className="w-full object-cover"
            />
          </div>
        </motion.div>
        
        {/* Bannière explicative du concept RAID 0 Neuronal */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mb-16 rounded-2xl relative overflow-hidden"
          style={{ 
            backgroundColor: 'rgba(0,0,0,0.5)', 
            border: `1px solid ${avengersColors.gray}`,
          }}
        >
          <div className="flex flex-col p-8">
            <div className="md:w-full">
              <h3 className="text-2xl font-bold text-white mb-4">Système RAID 0 Neuronal by Mr D</h3>
              <p className="text-gray-300 mb-4">
                Une percée révolutionnaire dans le clustering ML : le premier système de distribution neuronale utilisant 
                l'architecture RAID 0 pour unifier deux Mac Apple Silicon (Mac Studio Ultra + M4 Max) en un super cluster ML. 
                Développé en collaboration avec une IA exceptionnelle, il a fonctionné silencieusement pendant 48h avant même que nous le remarquions !
              </p>
              <div className="flex flex-wrap gap-3 mt-4">
                <div className="px-4 py-2 rounded-lg text-white font-bold" style={{ backgroundColor: avengersColors.red }}>
                  72.8 Gb/s débit effectif
                </div>
                <div className="px-4 py-2 rounded-lg text-white font-bold" style={{ backgroundColor: avengersColors.blue }}>
                  50µs latence inter-nœuds
                </div>
                <div className="px-4 py-2 rounded-lg text-white font-bold" style={{ backgroundColor: avengersColors.purple }}>
                  99.99% disponibilité
                </div>
              </div>
            </div>
          </div>
          
          {/* Pied coloré pour la bannière */}
          <div className="h-4" style={{ background: `linear-gradient(to right, ${avengersColors.blue}, ${avengersColors.red}, ${avengersColors.purple})` }}></div>
        </motion.div>
        
        {/* Tuiles des Swiss Avengers avec pieds colorés */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          {heroTypes.map((hero, index) => (
            <motion.div
              key={`icon-card-${hero.id}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              className="rounded-xl overflow-hidden shadow-2xl transform transition-all duration-300 flex flex-col"
              style={{ 
                backgroundColor: 'rgba(0,0,0,0.4)', 
                border: `2px solid ${hero.color}` 
              }}
            >
              <div className="flex-grow p-6 text-center">
                <div className="flex justify-center mb-4">
                  <div className="w-24 h-24 rounded-full p-1 flex items-center justify-center" 
                       style={{ backgroundColor: 'rgba(255,255,255,0.1)', border: `2px solid ${hero.color}` }}>
                    <img 
                      src={hero.icon} 
                      alt={hero.name} 
                      className="w-18 h-18 object-contain" 
                    />
                  </div>
                </div>
                <h3 className="text-2xl font-bold text-white mb-3">{hero.name}</h3>
                <p className="text-gray-300 mb-5 font-medium">{hero.description.split(' ').slice(0, 12).join(' ')}...</p>
              </div>
              
              {/* Pied coloré avec bouton */}
              <div className="p-4 flex justify-center items-center" style={{ backgroundColor: hero.color }}>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  className="w-12 h-12 rounded-full flex items-center justify-center cursor-pointer bg-white/20"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </motion.div>
              </div>
            </motion.div>
          ))}
        </div>
        
        {/* Banner with innovation details */}
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 0.6 }}
          className="rounded-3xl overflow-hidden mb-16 shadow-2xl"
          style={{ 
            backgroundColor: 'rgba(0,0,0,0.4)',
            border: `2px solid ${avengersColors.blue}`
          }}
        >
          <div className="flex flex-col md:flex-row gap-8 p-12">
            <div className="md:w-1/2">
              <h3 className="text-2xl font-bold mb-4 text-white">Performance du Cluster</h3>
              <ul className="space-y-3 text-gray-300">
                <li className="flex items-center">
                  <span className="mr-2">📊</span> 72.8 Gb/s débit effectif via Thunderbolt 4
                </li>
                <li className="flex items-center">
                  <span className="mr-2">⚡️</span> Ultra-faible latence inter-nœuds de 50µs
                </li>
                <li className="flex items-center">
                  <span className="mr-2">🧠</span> 177MB/engine (optimisation de 23%)
                </li>
                <li className="flex items-center">
                  <span className="mr-2">🔄</span> 99.99% disponibilité sur 48h
                </li>
                <li className="flex items-center">
                  <span className="mr-2">💫</span> Synchronisation des Moteurs Neuronaux
                </li>
              </ul>
            </div>
            <div className="md:w-1/2">
              <h3 className="text-2xl font-bold mb-4 text-white">Innovation du Cluster</h3>
              <ul className="space-y-3 text-gray-300">
                <li>• Neural RAID 0: distribution des tenseurs en stripes de 96KB</li>
                <li>• Synchronisation Metal native entre machines</li>
                <li>• Pipeline unifié Mac Studio Ultra 2 et Ultra 3 ↔️ M4 Max</li>
                <li>• Scaling quasi-linéaire entre les Moteurs Neuronaux</li>
              </ul>
            </div>
          </div>
          
          {/* Pied coloré pour la bannière */}
          <div className="h-4" style={{ background: `linear-gradient(to right, ${avengersColors.blue}, ${avengersColors.purple})` }}></div>
        </motion.div>
        
        {/* Call to action */}
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 0.6 }}
          className="rounded-3xl overflow-hidden mb-12 shadow-2xl"
          style={{ 
            backgroundColor: 'rgba(0,0,0,0.4)',
            border: `2px solid ${avengersColors.red}`
          }}
        >
          <div className="relative p-12">
            <div className="absolute top-0 right-0 w-64 h-64 rounded-full filter blur-3xl opacity-20 transform translate-x-20 -translate-y-20"
                 style={{ backgroundColor: avengersColors.red }}></div>
            
            <h3 className="text-3xl md:text-4xl font-bold mb-6 max-w-2xl text-white">Ensemble, nous construisons une IA suisse responsable et performante</h3>
            
            <p className="text-gray-300 mb-8 max-w-xl text-lg">
              Rejoignez notre alliance de talents pour façonner l'avenir de l'intelligence artificielle 
              en Suisse. Ensemble, nous luttons contre les IA corrompues et manipulées grâce à notre 
              infrastructure locale ultra-puissante.
            </p>
            
            <button className="px-8 py-4 rounded-full font-semibold text-lg transition-colors shadow-lg"
                    style={{ background: avengersColors.red, color: 'white' }}>
              Participez à l'Innovation
            </button>
          </div>
          
          {/* Pied coloré pour la bannière */}
          <div className="h-4" style={{ background: `linear-gradient(to right, ${avengersColors.red}, ${avengersColors.purple})` }}></div>
        </motion.div>
      </div>
    </section>
  );
};

export default SwissAvengers; 