import { motion } from 'framer-motion';

type ImpactItem = {
  id: string;
  title: string;
  description: string;
  icon: string;
  color: string;
  delay: number;
};

const impactItems: ImpactItem[] = [
  {
    id: 'innovation-locale',
    title: 'Innovation Locale',
    description: "Infrastructure locale optimisée pour l'IA haute performance, sécurisée et souveraine.",
    icon: '/images/local-innovation-icon.png',
    color: 'bg-gradient-blue',
    delay: 0,
  },
  {
    id: 'transformation-digitale',
    title: 'Transformation Digitale',
    description: 'Accompagnement des entreprises dans leur transformation numérique avec des solutions IA éthiques.',
    icon: '/images/digital-impact-icon.png',
    color: 'bg-gradient-purple',
    delay: 0.1,
  },
  {
    id: 'impact-durable',
    title: 'Impact Durable',
    description: "Solutions éco-responsables et durables pour un impact positif sur l'environnement.",
    icon: '/images/sustainable-impact-icon.png',
    color: 'bg-gradient-green',
    delay: 0.2,
  },
];

// Nouveaux éléments thématiques avec les images réelles
const thematicItems = [
  { 
    id: "showcase",
    title: "Showcase", 
    subtitle: "Projets d'innovation", 
    image: "/images/showcase-icon.png",
    gradient: "linear-gradient(135deg, #0066cc, #5ac8fa)"
  },
  { 
    id: "components",
    title: "Modular Tech", 
    subtitle: "Architecture flexible", 
    image: "/images/component-icon.png",
    gradient: "linear-gradient(135deg, #ff9500, #ff2d55)" 
  },
  { 
    id: "ai",
    title: "Intelligence", 
    subtitle: "Solutions IA avancées", 
    image: "/images/ai-brain-icon.png",
    gradient: "linear-gradient(135deg, #5e5ce6, #bf5af2)"
  },
  { 
    id: "robotics",
    title: "Robotique", 
    subtitle: "Assistance à la marche", 
    image: "/images/assistive-walk-icon.png",
    gradient: "linear-gradient(135deg, #34c759, #30d5c8)"
  }
];

const ImpactGrid = () => {
  return (
    <section className="py-12 bg-gray-50" id="impact">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-12"
        >
          <span className="text-xl font-medium inline-block mb-3"
                style={{
                  background: 'linear-gradient(90deg, #0071e3, #40a8ff)',
                  WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}>
            Notre Impact
          </span>
          <h2 className="text-4xl md:text-5xl font-semibold mb-6 text-[#333333]">
            Global Impact
          </h2>
          <p className="max-w-2xl mx-auto text-lg text-[#666666]">
            Our technologies are transforming lives and empowering communities across the globe.
          </p>
        </motion.div>

        {/* Éléments thématiques avec images */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-20">
          {thematicItems.map((item, index) => (
            <motion.div
              key={`thematic-${index}`}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              whileHover={{ y: -10, transition: { duration: 0.3 } }}
              className="text-center p-6 rounded-2xl overflow-hidden"
              style={{ 
                boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
                backgroundColor: 'white',
                position: 'relative',
                transform: 'translateZ(0)',
                animation: `float 6s ease-in-out infinite ${index * 0.5}s`
              }}
            >
              <div 
                className="w-16 h-16 mx-auto mb-4 rounded-full flex items-center justify-center"
                style={{ 
                  background: item.gradient,
                  padding: '2px'
                }}
              >
                <img 
                  src={item.image} 
                  alt={item.title} 
                  className="w-10 h-10 object-contain"
                />
              </div>
              <h3 className="text-xl font-semibold text-[#333333] mb-1">{item.title}</h3>
              <p className="text-sm text-[#666666]">{item.subtitle}</p>
              
              {/* Effet de lumière */}
              <div style={{
                position: 'absolute',
                width: '150px',
                height: '150px',
                borderRadius: '50%',
                background: 'radial-gradient(circle, rgba(92, 170, 255, 0.15) 0%, rgba(255, 255, 255, 0) 70%)',
                zIndex: -1,
                opacity: 0.6,
                top: '-30px',
                right: '-30px',
                filter: 'blur(30px)'
              }}></div>
            </motion.div>
          ))}
        </div>

        {/* Cartes d'impact */}
        <div className="grid md:grid-cols-3 gap-8">
          {impactItems.map((item) => (
            <motion.div
              key={item.id}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: item.delay }}
              whileHover={{ y: -15, transition: { duration: 0.3 } }}
              className="impact-card"
              style={{
                animation: `float 6s ease-in-out infinite ${item.delay * 2}s`,
                boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
                borderRadius: '24px',
                overflow: 'hidden',
                backgroundColor: 'white',
                transform: 'translateZ(0)',
                position: 'relative'
              }}
            >
              <div 
                style={{
                  background: item.id === 'innovation-locale' 
                    ? 'linear-gradient(135deg, #0066cc, #5ac8fa)' 
                    : item.id === 'transformation-digitale'
                      ? 'linear-gradient(135deg, #5e5ce6, #bf5af2)'
                      : 'linear-gradient(135deg, #34c759, #30d5c8)',
                  padding: '20px',
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px'
                }}
              >
                <img 
                  src={item.icon} 
                  alt={item.title} 
                  className="w-20 h-20 mx-auto my-3 drop-shadow-lg"
                  style={{ display: 'block' }}
                />
              </div>
              <div style={{ padding: '24px' }}>
                <h3 style={{ fontSize: '22px', fontWeight: 600, marginBottom: '10px', color: '#333333' }}>{item.title}</h3>
                <p style={{ fontSize: '16px', color: '#666666', lineHeight: 1.5 }}>{item.description}</p>
                <div className="mt-5">
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    style={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '36px',
                      height: '36px',
                      borderRadius: '50%',
                      backgroundColor: item.id === 'innovation-locale' 
                        ? '#0066cc' 
                        : item.id === 'transformation-digitale'
                          ? '#5e5ce6'
                          : '#34c759',
                      color: 'white',
                      border: 'none',
                      padding: 0,
                      cursor: 'pointer',
                      boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
                    }}
                  >
                    <svg 
                      className="w-5 h-5" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24" 
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M9 5l7 7-7 7" 
                      />
                    </svg>
                  </motion.button>
                </div>
              </div>

              {/* Effet de lumière */}
              <div 
                style={{
                  position: 'absolute',
                  width: '300px',
                  height: '300px',
                  borderRadius: '50%',
                  background: item.id === 'innovation-locale' 
                    ? 'radial-gradient(circle, rgba(92, 170, 255, 0.15) 0%, rgba(255, 255, 255, 0) 70%)' 
                    : item.id === 'transformation-digitale'
                      ? 'radial-gradient(circle, rgba(191, 90, 242, 0.15) 0%, rgba(255, 255, 255, 0) 70%)'
                      : 'radial-gradient(circle, rgba(52, 199, 89, 0.15) 0%, rgba(255, 255, 255, 0) 70%)',
                  zIndex: -1,
                  opacity: 0.6,
                  top: '-40px',
                  right: '-40px',
                  filter: 'blur(40px)'
                }}
              ></div>
            </motion.div>
          ))}
        </div>

        {/* CTA */}
        <div className="text-center mt-16">
          <motion.button
            whileHover={{ y: -5 }}
            className="px-8 py-4 bg-[#0071e3] text-white font-semibold rounded-full shadow-lg hover:shadow-xl transition-all"
          >
            View Success Stories
          </motion.button>
        </div>
      </div>

      <style>
        {`
          @keyframes float {
            0% { transform: translateY(0px); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0px); }
          }
        `}
      </style>
    </section>
  );
};

export default ImpactGrid; 