import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { blogPosts } from '../data/blog-posts';
import { motion } from 'framer-motion';

// Fonction pour générer un slug à partir d'un titre/ID
function generateSlug(text: string): string {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/['']/g, '') // Supprimer les apostrophes
    .replace(/[?]/g, '') // Supprimer les points d'interrogation
    .replace(/[.]/g, '') // Supprimer les points
    .replace(/\s+/g, '-') // Remplacer les espaces par des tirets
    .replace(/[^\w-]/g, '') // Supprimer tous les caractères non-mot
    .replace(/--+/g, '-'); // Remplacer les multiples tirets par un seul
}

// Couleurs Avengers
const avengersColors = {
  red: '#a50000',
  blue: '#3268bd',
  orange: '#ff9500', // Couleur de la tuile Music AI
  purple: '#460061'
};

const BreakingNewsBanner: React.FC = () => {
  const [latestPost, setLatestPost] = useState<any | null>(null);
  const [windowWidth, setWindowWidth] = useState(0);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    // Marquer le composant comme monté (côté client)
    setIsMounted(true);
    
    // Récupérer la largeur de la fenêtre
    setWindowWidth(window.innerWidth);
    
    // Ajuster la largeur de la fenêtre si elle change
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    
    // Trier les articles par date et prendre le plus récent
    if (blogPosts && blogPosts.length > 0) {
      const sortedPosts = [...blogPosts].sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
      setLatestPost(sortedPosts[0]);
    }
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Ne rien rendre pendant le rendu côté serveur ou si aucun article n'est disponible
  if (!isMounted || !latestPost) return null;

  const slug = generateSlug(latestPost.id);
  
  // Créer un contenu plus long pour s'assurer qu'il y a toujours du texte visible
  const repeatedContent = (
    <>
      <span className="inline-block px-3 py-1 bg-white/20 text-white font-semibold rounded-full mx-4">
        DERNIÈRES INFOS
      </span>
      <Link 
        to={`/blog/${slug}`} 
        className="text-lg hover:underline"
        style={{ 
          background: `linear-gradient(to right, ${avengersColors.orange}, ${avengersColors.red}, ${avengersColors.blue})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          color: avengersColors.orange, // Fallback
          fontWeight: 600
        }}
      >
        {latestPost.title} — {new Date(latestPost.date).toLocaleDateString('fr-FR')}
      </Link>
      <span className="mx-8 text-white">•</span>
      <span className="text-white/90">
        {latestPost.category ? `Catégorie: ${latestPost.category}` : ''}
      </span>
      <span className="mx-8 text-white">•</span>
      <span className="mx-32"></span> {/* Espace supplémentaire entre les répétitions - comme un "wagon d'espace" */}
    </>
  );
  
  return (
    <div className="fixed top-0 left-0 right-0 z-[999]" style={{ 
      background: 'linear-gradient(90deg, #0071e3, #40a8ff)',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    }}>
      <div className="relative overflow-hidden py-2" style={{ height: '40px' }}>
        {windowWidth > 0 && (
          <div className="relative" style={{ height: '100%', overflow: 'hidden' }}>
            {/* Premier élément qui défile */}
            <motion.div
              className="whitespace-nowrap flex items-center absolute"
              initial={{ x: windowWidth }}
              animate={{ x: -windowWidth * 1.5 }}
              transition={{
                duration: 35, // Défilement plus lent (35 secondes au lieu de 20)
                repeat: Infinity,
                ease: "linear",
                repeatType: "loop"
              }}
            >
              {repeatedContent}
              {repeatedContent}
              {repeatedContent}
            </motion.div>
            
            {/* Deuxième élément qui défile (pour une boucle continue) */}
            <motion.div
              className="whitespace-nowrap flex items-center absolute"
              initial={{ x: windowWidth * 2.5 }}
              animate={{ x: 0 }}
              transition={{
                duration: 35, // Défilement plus lent (35 secondes au lieu de 20)
                repeat: Infinity,
                ease: "linear",
                repeatType: "loop"
              }}
            >
              {repeatedContent}
              {repeatedContent}
              {repeatedContent}
            </motion.div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BreakingNewsBanner; 